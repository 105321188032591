import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Col, Tooltip } from 'antd';
import { Form, Select, DatePicker } from 'formik-antd';
import { Formik } from 'formik';
import { FaSearch } from 'react-icons/fa';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { format, subDays, addDays } from 'date-fns';
import axios from 'axios';
import { useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';

import EditForm from '~/pages/Financial/form.js';

import api from '~/services/api';
import PageTitle from '~/components/PageTitle';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { Table, TableHeader } from '~/components/Table';
import { formatPrice } from 'Utils';

export default function EntireReview() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  // eslint-disable-next-line
  const [recordData, setRecordData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [itens, setItens] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  // eslint-disable-next-line
  const [selectedRecord, setSelectedRecord] = useState(null);
  const token = useSelector(state => state.auth.token);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [generateExcelButton, setGenerateExcelButton] = useState('none');
  const [actualFilters, setActualFilters] = useState([]);

  const { Option } = Select;

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/tasks/findAll');
      setTasks(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCostCenters = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/costCenters/findAll');
      setCostCenters(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCollaborators = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/findAll');
      // data.splice(0, 0, {
      //   id: 0,
      //   name: 'Todos',
      // });
      setCollaborators(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/expenses`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
        data: actualFilters,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio.xlsx');
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleItem = async value => {
    const { data } = await api.get('/items/findByTaskId', {
      params: { taskId: value },
    });

    setItens(data);
  };

  const disabledDate = current => {
    const tooLate = startDate && current.diff(startDate, 'days') > 365;
    const beforeStart = current && current.valueOf() < startDate;
    const tooEarly = endDate && endDate.diff(current, 'days') > 365;
    const afterEnd = current && current.valueOf() > endDate;
    const future = current && current.valueOf() > Date.now();
    return tooEarly || tooLate || beforeStart || afterEnd || future;
  };

  const handleSearch = async values => {
    setLoading(true);
    var filter = [];

    if (values.collaborator !== undefined && values.collaborator.length > 0) {
      filter.push({
        columnJoin: 'user',
        field: 'id',
        value: values.collaborator,
        restriction: 'IN',
      });
    }
    if (values.costCenter !== undefined && values.costCenter.length > 0) {
      filter.push({
        columnJoin: 'costCenter',
        field: 'id',
        value: values.costCenter,
        restriction: 'IN',
      });
    }
    if (values.task !== undefined && values.task !== 0) {
      filter.push({
        columnJoin: 'item',
        columnSecondJoin: 'budgetaryNature',
        columnThirdJoin: 'task',
        field: 'id',
        value: [values.task],
        restriction: 'IN',
      });
    }
    if (values.item !== undefined && values.item.length > 0) {
      filter.push({
        columnJoin: 'item',
        field: 'id',
        value: values.item,
        restriction: 'IN',
      });
    }
    if (values.start && values.end) {
      filter.push(
        {
          field: 'releaseDate',
          value: format(new Date(values.start), 'dd-MM-yyyy'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        },
        {
          field: 'releaseDate',
          value: format(new Date(values.end), 'dd-MM-yyyy'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        }
      );
    } else if (values.start && !values.end) {
      filter.push(
        {
          field: 'releaseDate',
          value: format(new Date(values.start), 'dd-MM-yyyy'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        },
        {
          field: 'releaseDate',
          value: format(addDays(new Date(values.start), 60), 'dd-MM-yyyy'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        }
      );
    } else if (!values.start && values.end) {
      filter.push(
        {
          field: 'releaseDate',
          value: format(subDays(new Date(values.end), 60), 'dd-MM-yyyy'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        },
        {
          field: 'releaseDate',
          value: format(new Date(values.end), 'dd-MM-yyyy'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        }
      );
    } else {
      filter.push(
        {
          field: 'releaseDate',
          value: format(subDays(new Date(Date.now()), 60), 'dd-MM-yyyy'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        },
        {
          field: 'releaseDate',
          value: format(new Date(Date.now()), 'dd-MM-yyyy'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        }
      );
    }
    filter.push({
      field: 'expenseStatus',
      value: ['PAID'],
      restriction: 'IN',
    });



    setActualFilters(filter);
    try {
      const { data } = await api.post('/expenses/findExpenseReport', filter);
      setRecordData(data);
      setTableData(data);
      setGenerateExcelButton('block');
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCostCenters();
    fetchCollaborators();
    fetchTasks();
  }, [showForm]);

  const tableColumns = [
    {
      title: t('screens:review.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('screens:review.collaborator'),
      dataIndex: 'user.name',
      key: 'user.name',
    },
    {
      title: t('screens:review.costCenter'),
      dataIndex: 'costCenter.clientName',
      key: 'costCenter.clientName',
    },
    {
      title: t('screens:review.task'),
      dataIndex: 'item.budgetaryNature.task.name',
      key: 'item.budgetaryNature.task.name',
    },
    {
      title: t('screens:review.item'),
      dataIndex: 'item.name',
      key: 'item.name',
    },
    {
      title: t('screens:review.value'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => formatPrice(record.cost),
    },
  ];

  return (
    <DefautLayout>
      <Box>
        <PageTitle title={`RDV > ${t('screens:review.titleEntires')}`} />
        <Formik
          initialValues={{
            status: 'Pago',
          }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, isSubmitting, submitForm, resetForm }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl field="costCenters" label={t('screens:review.costCenter')}>
                      <Select
                        style={{ whiteSpace: 'nowrap', height: '32px', overflow: 'auto' }}
                        mode="multiple"
                        showArrow
                        name="costCenter"
                        disabled={costCenters.length <= 0}
                        placeholder={t('messages:select')}
                      >
                        {costCenters.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.code} - {item.clientName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl field="collaborator" label={t('screens:review.collaborator')}>
                      <Select
                        style={{ whiteSpace: 'nowrap', height: '32px', overflow: 'auto' }}
                        mode="multiple"
                        showArrow
                        name="collaborator"
                        disabled={collaborators.length <= 0}
                        placeholder={t('messages:select')}
                      >
                        {collaborators.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <FormControl
                      field="start"
                      label={
                        <span>
                          {t('screens:review.from')}&nbsp;
                          <Tooltip title="Máximo de 365 dias (1 ano).">
                            <AiFillQuestionCircle />
                          </Tooltip>
                        </span>
                      }
                    >
                      <DatePicker
                        disabledDate={value => disabledDate(value)}
                        format="DD/MM/Y"
                        name="start"
                        placeholder={t('messages:select')}
                        onChange={value => {
                          setStartDate(value);
                        }}
                        locale={locale}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <FormControl
                      field="end"
                      label={
                        <span>
                          {t('screens:review.at')}&nbsp;
                          <Tooltip title="Máximo de 365 dias (1 ano).">
                            <AiFillQuestionCircle />
                          </Tooltip>
                        </span>
                      }
                    >
                      <DatePicker
                        disabledDate={value => disabledDate(value)}
                        format="DD/MM/Y"
                        name="end"
                        placeholder={t('messages:select')}
                        onChange={value => {
                          setEndDate(value);
                        }}
                        locale={locale}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl field="task" label={t('screens:review.task')}>
                      <Select
                        name="task"
                        disabled={tasks.length <= 0}
                        placeholder={t('messages:select')}
                        onChange={handleItem}
                      >
                        {tasks.map(task => {
                          return (
                            <Select.Option key={task.id} value={task.id}>
                              {task.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <FormControl field="item" label={t('screens:review.item')}>
                      <Select
                        style={{ whiteSpace: 'nowrap', height: '32px', overflow: 'auto' }}
                        mode="multiple"
                        showArrow
                        name="item"
                        disabled={itens.length <= 0}
                        placeholder={t('messages:select')}
                      >
                        {itens.map(item => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                    <FormControl field="status" label={t('screens:review.status')}>
                      <Select name="status" disabled />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Button type="submit" color="primary" style={{ marginLeft: 'auto' }}>
                    <FaSearch style={{ width: '18px' }} />
                    {t('screens:review.search')}
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      setStartDate();
                      setEndDate();
                      setGenerateExcelButton('none');
                      setActualFilters([]);
                      setRecordData([]);
                      setTableData([]);
                      setItens([]);
                    }}
                    color="default"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
        <TableHeader>
          <Button
            style={{ display: generateExcelButton, marginTop: '15px' }}
            onClick={() => {
              handleDownload();
            }}
            color="success"
          >
            {t('screens:review.generateExcel')}
          </Button>
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
        <EditForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
      </Box>
    </DefautLayout>
  );
}
